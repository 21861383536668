<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.evaluated_participant_list')}}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="evaluation" :class="'btn btn-success text-light'">{{ $t('elearning_tim.pre_post_evaluation') }} {{ $t('globalTrans.list') }}</router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + 1) }}
                                      </template>
                                      <template v-slot:cell(training_title)="data">
                                        <span class="capitalize">{{ data.item.training_title }}</span>
                                      </template>
                                      <template v-slot:cell(exam_date)="data">
                                        <span class="capitalize">{{ data.item.exam_date | dateFormat }}</span>
                                      </template>
                                      <template v-slot:cell(mobile)="data">
                                        <span class="capitalize">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}</span>
                                      </template>
                                      <template v-slot:cell(total_marks)="data">
                                        <span class="">{{ $n(data.item.total_marks) }}</span>
                                      </template>
                                      <template v-slot:cell(obtained_marks)="data">
                                        <span class="">{{ $n(data.item.obtained_marks) }}</span>
                                      </template>
                                      <template v-slot:cell(exam_type)="data">
                                        <span class="">{{ examTypeData(data.item.exam_type) }}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_view mr-1" v-b-modal.modal-4 @click="edit(data.item)">
                                          <i class="fas fa-eye"></i>
                                        </a>
                                      </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('elearning_tim.pre_post_evaluation')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('elearning_tim.pre_post_evaluation') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
            </b-button>
            <b-button variant="primary" @click="pdfDownload" class="mr-2 float-right">
              <i class="fas fa-print"></i> {{  $t('globalTrans.download') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { evaluationUserList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'
import Details from './UserDetails'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
      return {
        search: {
        },
        circularLoading: false,
        item: []
      }
    },
    computed: {
      examTypeList: function () {
        return [
          { text: this.currentLocale === 'en' ? 'Pre-Evaluation' : 'প্রি-ইভালুয়েশন', value: 1, text_en: 'Pre-Evaluation', text_bn: 'প্রি-ইভালুয়েশন' },
          { text: this.currentLocale === 'en' ? 'Post-Evaluation' : 'পোস্ট-ইভালুয়েশন', value: 2, text_en: 'Post-Evaluation', text_bn: 'পোস্ট-ইভালুয়েশন' }
        ]
      },
      allBatchList () {
        const batchList = [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
        return batchList
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('elearning_config.update')
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('elearning_tim.evaluation_type'), class: 'text-center' },
          { label: this.$t('elearning_tim.evaluation_date'), class: 'text-center' },
          { label: this.$t('elearning_config.organization'), class: 'text-center' },
          { label: this.$t('elearning_config.training_title'), class: 'text-center' },
          { label: this.$t('globalTrans.name'), class: 'text-center' },
          { label: this.$t('globalTrans.mobile'), class: 'text-center' },
          { label: this.$t('elearning_tim.marks'), class: 'text-center' },
          { label: this.$t('elearning_tim.obtained_marks'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'index' },
          { key: 'exam_type' },
          { key: 'exam_date' },
          { key: 'org_bn' },
          { key: 'training_title_bn' },
          { key: 'name_bn' },
          { key: 'mobile' },
          { key: 'total_marks' },
          { key: 'obtained_marks' },
          { key: 'action' }
          ]
        } else {
          keys = [
          { key: 'index' },
          { key: 'exam_type' },
          { key: 'exam_date' },
          { key: 'org' },
          { key: 'training_title' },
          { key: 'name' },
          { key: 'mobile' },
          { key: 'total_marks' },
          { key: 'obtained_marks' },
          { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    async created () {
      this.loadData()
    },
    watch: {
    },
    mounted () {
      core.index()
      flatpickr('.fromDate', {})
    },
    methods: {
      getTypeList (orgId) {
        if (this.search.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
          if (orgId) {
            return type.filter(item => item.org_id === parseInt(orgId))
          }
          return type
        }
      },
      getCategoryList (typeId) {
        if (this.search.training_type_id) {
          const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
          if (typeId) {
            return trainingCategoryList.filter(item => item.training_type_id === typeId)
          }
          return trainingCategoryList
        }
      },
      getTrainingTitleList (categoryId) {
        if (this.search.training_category_id) {
          const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
          if (categoryId) {
            return trainingTitleList.filter(item => item.training_category_id === categoryId)
          }
          return trainingTitleList
        }
      },
      loadData () {
        const params = {
          tim_evaluation_id: this.$route.query.id,
          circular_memo_no: this.$route.query.circularMemoNo
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.getData(trainingElearningServiceBaseUrl, evaluationUserList, params).then(response => {
          if (response.success) {
            this.$store.dispatch('setList', this.getCustomDataList(response.data))
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          if (typeof orgObj !== 'undefined') {
            customItem.org = orgObj.text_en
            customItem.org_bn = orgObj.text_bn
          } else {
            customItem.org = ''
            customItem.org_bn = ''
          }
            let totalMarks = 0
            let totalObtainedMarks = 0
            item.course_evaluation_answers.filter(answer => {
              totalMarks += answer.evaluation_question.marks
              totalObtainedMarks += answer.marks
            })
            customItem.total_marks = totalMarks
            customItem.obtained_marks = totalObtainedMarks
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          if (typeof OfficeTypeListObj !== 'undefined') {
            customItem.office_type = OfficeTypeListObj.text_en
            customItem.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            customItem.office_type = ''
            customItem.office_type_bn = ''
          }
          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            customItem.office = officeObj.text_en
            customItem.office_bn = officeObj.text_bn
          } else {
            customItem.office = ''
            customItem.office_bn = ''
          }
          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          if (typeof fiscalYearObj !== 'undefined') {
            customItem.fiscal_year = fiscalYearObj.text_en
            customItem.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            customItem.fiscal_year = ''
            customItem.fiscal_year_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          if (typeof trainingTypeObj !== 'undefined') {
            customItem.training_type = trainingTypeObj.text_en
            customItem.training_type_bn = trainingTypeObj.text_bn
          } else {
            customItem.training_type = ''
            customItem.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          if (typeof trainingCategoryObj !== 'undefined') {
            customItem.training_category = trainingCategoryObj.text_en
            customItem.training_category_bn = trainingCategoryObj.text_bn
          } else {
            customItem.training_category = ''
            customItem.training_category_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          if (typeof trainingTitleObj !== 'undefined') {
            customItem.training_title = trainingTitleObj.text_en
            customItem.training_title_bn = trainingTitleObj.text_bn
          } else {
            customItem.training_title = ''
            customItem.training_title_bn = ''
          }
          const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(item.course_id))
          if (typeof courseObj !== 'undefined') {
            customItem.course_name = courseObj.text_en
            customItem.course_name_bn = courseObj.text_bn
          } else {
            customItem.course_name = ''
            customItem.course_name_bn = ''
          }

          return Object.assign({}, item, customItem)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      pdfDownload () {
        this.$refs.details.pdfDownload()
      },
      examTypeData (id) {
        const batchData = this.examTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return batchData !== undefined ? batchData.text_bn : ''
        } else {
            return batchData !== undefined ? batchData.text_en : ''
        }
      }
    }
}
</script>

<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
